<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Tables from "@/components/elements/Tables.vue";
import swal from "sweetalert2";
import {
  GetAllContractTypes,
  AddContractTypes,
  UpdateContractType,
  DeleteContractType,
} from "@/services/contracts.js";
/**
 * Typography component
 */
export default {
  page: {
    title: "Contract",
    meta: [{ name: "Contract", content: appConfig.description }],
  },
  components: { Tables, Layout, PageHeader },
  data() {
    return {
      title: this.$t("menuitems.contract_types.text"),
      id: "",
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.contract_types.text"),
        },
        {
          text: this.$t("all"),
          active: true,
        },
      ],
      fields: [
        { label: this.$t("type"), key: "name", sortable: false },
        { label: this.$t("edit"), key: "edit", sortable: false },
      ],
      tableData: [],
      contractTypesData: [],
      editData: { nameEn: "", editnameEn: "", nameAr: "", editnameAr: "" },
      AddData: { nameEn: "", nameAr: "" },
      AddQuestionsNumbers: 1,
      editQuestionsNumbers: 1,
    };
  },
  methods: {
    editClicked(item) {
      const locale = this.$i18n.locale;
      const index = this.contractTypesData.findIndex((el) =>
        locale == "en" ? el.en : el.ar === item.item.name
      );
      console.log(index);
      this.editData.nameEn = this.contractTypesData[index].en;
      this.editData.editnameEn = this.contractTypesData[index].en;
      this.editData.nameAr = this.contractTypesData[index].ar;
      this.editData.editnameAr = this.contractTypesData[index].ar;
      this.$bvModal.show("modal-edit");
    },
    add() {
      AddContractTypes(this.id, this.AddData.nameEn, this.AddData.nameAr).then(
        () => {
          this.$bvModal.hide("modal-add");
          this.successmsg("contract type added succesffully");
          this.loadContractTypes();
        }
      );
    },
    edit() {
      UpdateContractType(this.id, this.editData).then(() => {
        this.$bvModal.hide("modal-edit");
        this.successmsg("contract type edited succesffully");
        this.loadContractTypes();
      });
    },
    deletee() {
      swal
        .fire({
          position: "center",
          icon: "question",
          title: "Are you Sure Want To Delete This Item",
        })
        .then((res) => {
          if (res) {
            DeleteContractType(
              this.id,
              this.editData.nameEn,
              this.editData.nameAr
            ).then(() => {
              this.successmsg("Deleted Successfully");
              this.$bvModal.hide("modal-edit");
              this.loadContractTypes();
            });
          }
        });
    },
    successmsg(msg) {
      swal.fire({
        position: "center",
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async loadContractTypes() {
      GetAllContractTypes().then((data) => {
        this.id = data[0].id;
        const locale = this.$i18n.locale;
        this.contractTypesData = data[0].ContractTypes;
        console.log(this.contractTypesData);
        this.tableData = data[0].ContractTypes.map((type) => {
          return { name: locale == "en" ? type.en : type.ar };
        });
      });
    },
  },
  created() {
    this.loadContractTypes();
  },
};
</script>

<template>
  <Layout
    ><PageHeader :title="title" :items="items" />
    <Tables
      :table-data="tableData"
      :fields="fields"
      :addbtn="true"
      @editClicked="editClicked"
      :hideShowMore="true"
    />
    <b-modal
      no-close-on-backdrop
      id="modal-edit"
      :title="$t('edit_contract_type')"
      centered
      scrollable
      title-class="font-18"
    >
      <template #modal-footer>
        <div></div>
      </template>
      <form @submit.prevent="edit">
        <input
          type="text"
          :placeholder="$t('Please_Enter_Contract_Type_Name_in_en')"
          v-model="editData.editnameEn"
          class="form-control mb-4"
          required
        />
        <input
          type="text"
          :placeholder="$t('Please_Enter_Contract_Type_Name_in_ar')"
          v-model="editData.editnameAr"
          class="form-control mb-4"
          required
        />
        <b-button
          style="margin-bottom: 10px"
          pill
          @click="deletee"
          variant="danger"
          >{{ $t("delete") }}</b-button
        >
        <b-row
          ><b-col cols="8"></b-col>
          <b-col cols="2"
            ><b-button @click="$bvModal.hide('modal-edit')">{{
              $t("cancel")
            }}</b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="primary" type="submit">{{
              $t("ok")
            }}</b-button></b-col
          ></b-row
        >
      </form>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="modal-add"
      :title="$t('add_contract_type')"
      centered
      scrollable
      title-class="font-18"
    >
      <template #modal-footer>
        <div></div>
      </template>
      <form @submit.prevent="add">
        <input
          type="text"
          :placeholder="$t('Please_Enter_Contract_Type_Name_in_en')"
          v-model="AddData.nameEn"
          class="form-control mb-4"
          required
        />
        <input
          type="text"
          :placeholder="$t('Please_Enter_Contract_Type_Name_in_ar')"
          v-model="AddData.nameAr"
          class="form-control mb-4"
          required
        />
        <b-row
          ><b-col cols="8"></b-col>
          <b-col cols="2"
            ><b-button @click="$bvModal.hide('modal-add')">{{
              $t("cancel")
            }}</b-button>
          </b-col>
          <b-col cols="2">
            <b-button variant="primary" type="submit">{{
              $t("ok")
            }}</b-button></b-col
          ></b-row
        >
      </form>
    </b-modal>
  </Layout>
</template>
